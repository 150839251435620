const webURL = 'https://staging.admin.reposit.co.uk';
const clientId = 'ywmFVbHFfMqIyiMysck24FxxlCYiUMkh';

export const appConfig = {
  links: {
    auth0: {
      logout: `https://reposit-staging.eu.auth0.com/v2/logout?client_id=${clientId}`,
      login: `https://reposit-staging.eu.auth0.com/authorize?response_type=token&client_id=${clientId}&redirect_uri=${webURL}/authenticate&audience=https://staging.api.com&scope=ALL`,
    },
    local: {
      login: `${webURL}/login`,
    },
  },
  api: {
    basePath: 'https://staging.api.reposit.co.uk',
  },
  earliestKpiDate: '2020-06-26',
  earliestReconciliationDate: '2023-01-01',
  inMaintenanceMode: false,
};
